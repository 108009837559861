import { useTranslation } from "react-i18next";
import "./TestimonialChip.css";
/**Parametros que debe recibir:
 * knowMoreHandler: Es la URL para cuando le den al boton conoce mas.
 * contactUsHandler: ES el handler para cuando le den al ContactUs.
 * chipTitle: El titulo de la tarjeta.
 * chipDescription: L adescripcion de la tarjeta
 * chipLogo: El avatar o logo para utilizar en la tarjeta. Esto deberia tener un tamaño prestablecido esto lo veo later.
 */
type TestimonialChipProps = {
  memberName: string;
  memberPosition: string;
  memberBio?: string;
  memberAvatar?: string;
  chipHeight?: string;
};
export default function TestimonialChip({
  memberName,
  memberPosition,
  memberBio,
  memberAvatar,
  chipHeight,
}: TestimonialChipProps) {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="testimonial_chip-main_container"
      style={{ height: chipHeight }}
    >
      <div className="testimonial_chip-name_position_bio_avatar_container">
        <div className="testimonial_chip-avtar_container">
          <img src={memberAvatar} alt="" className="testimonial_chip-avatar" />
        </div>
        <div className="testimonial_chip-name_position_bio_container">
          <div className="testimonial_chip-bio">{memberBio}</div>
          <div>{/** Puse este div para agrupar a esta gente y mandarlos pa bajo ;) */}
            <div className="testimonial_chip-name">{memberName}</div>
            <div className="testimonial_chip-position">{memberPosition}</div>
          </div>
        </div>
      </div>
    </div>
  );
}
