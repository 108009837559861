import { createSlice } from "@reduxjs/toolkit";

interface globalState {
  servicesCarouselRunning: boolean; //ESTO NO SE USA BORRALO DESPUES
  activeServiceTabIndex:number;//Establecer el tab de los servicios que debe estar activo.
}

const initialState: globalState = {
    servicesCarouselRunning: true,
    activeServiceTabIndex:0,
};

const global = createSlice({
  name: "global",
  initialState,
  reducers: {
    updateServicesCarouselRunning(state, action) {
      state.servicesCarouselRunning = action.payload.carouselRunning;
    },
    updateActiveServiceTabIndex(state,action){
      state.activeServiceTabIndex = action.payload.updateActiveServiceTabIndex;
    },
  },
});

const { actions, reducer } = global;
const { updateServicesCarouselRunning,updateActiveServiceTabIndex} = actions;
export { updateServicesCarouselRunning,updateActiveServiceTabIndex};
export default reducer;
