import { useTranslation } from "react-i18next";
import ActionButton from "../../components/ActionButton/ActionButton";
import "./ServiceDetailsMobileDevices.css";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";

export default function ServiceDetailsMobileDevices() {
  const { t, i18n } = useTranslation();
  /**----------------------------------------------------------------------------- */
  /**STATE VARIABLES HERE                                                          */
  /**----------------------------------------------------------------------------- */
  const [showContactForm, setShowContactForm] = useState<boolean>(false);

  /**----------------------------------------------------------------------------- */
  /**HANDLERS HERE                                                                 */
  /**----------------------------------------------------------------------------- */
  const handleCloseContactFormModalClose = () => {
    setShowContactForm(false);
  };

  const handleContactUsClick = () => {
    setShowContactForm(true);
  };
  return (
    <div className="ServiceDetailsWebProgramming--main_container">
      <div className="ServiceDetailsWebProgramming--title_description_container">
        <div className="section_title">
          {t("ServiceDetailsMobileDevices-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** INVESTIGACION Y PLANIFICACION */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Planning-Research-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Planning-Research-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>

        {/** DEFINICION DE REQUISITOS */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Requirements-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Requirements-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** DISEÑO DE LA EXPERIENCIA DE USUARIO*/}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-UX-UI-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-UX-UI-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** ELECCION DE PLATAFORMA Y TECNOLOGIAS*/}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Platform-Select-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Platform-Select-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>

        {/** DESARROLLO DEL CODIGO */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Code-Develop-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Code-Develop-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** PRUEBAS UNITARIAS Y DE INTEGRACION */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Unit-Tests-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Unit-Tests-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** PRUEBAS BETA Y DE USUARIO */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Beta-Testing-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Beta-Testing-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>

        {/** OPTIMIZACION DEL RENDIMIENTO */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Optimization-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Optimization-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** SEGURIDAD*/}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Safety-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Safety-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** LANZAMIENTO Y DISTRIBUCION */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Launch-Distribution-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Launch-Distribution-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>
        {/** ############################################################# */}

        {/** MARKETING Y PROMOCION */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Marketing-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Marketing-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>
        {/** ############################################################# */}

        {/** SOPORTE CONTINUO Y ACTUALIZACIONES */}
        <div className="section_sub_title">
          {t("ServiceDetailsMobileDevices-Support-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsMobileDevices-Support-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>
        {/** ############################################################# */}

      </div>
      <div className="ServiceDetailsWebProgramming--logo_container">
        <img
          className="ServiceDetailsWebProgramming--logo"
          src="mobile_devices.jpg"
          alt=""
        />
      </div>

      {/**CONTACT FORM MODAL */}
      <div className="ServicesDetailWeb--contact_form_modal_container">
        <Modal
          children={<ContactForm subject=" Dispositivos Móviles"/>}
          isOpen={showContactForm}
          onClose={handleCloseContactFormModalClose}
        />
      </div>
    </div>
  );
}
