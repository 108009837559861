import { useTranslation } from "react-i18next";
import "./FooterSection.css";
import PayPalMeButton from "../PayPalMe/PayPalMeButton";

export default function FooterSection() {
  const { t, i18n } = useTranslation();
  return (
    <div className="footer_section_main_container">
        <div className="FooterSection--payment_methods_container">
        <PayPalMeButton />
      </div>
      <div className="FooterSection--company_name_year_container">
        <div className="footer_section_company_name">
          {t("footerSection_AutomaticMenTL")}
        </div>
        <div className="footer_section_year">2023</div>
      </div>
      
    </div>
  );
}
