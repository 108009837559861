import { useTranslation } from "react-i18next";
import ActionButton from "../ActionButton/ActionButton";
import "./TeamMemberChip.css";
/**Parametros que debe recibir:
 * knowMoreHandler: Es la URL para cuando le den al boton conoce mas.
 * contactUsHandler: ES el handler para cuando le den al ContactUs.
 * chipTitle: El titulo de la tarjeta.
 * chipDescription: L adescripcion de la tarjeta
 * chipLogo: El avatar o logo para utilizar en la tarjeta. Esto deberia tener un tamaño prestablecido esto lo veo later.
 */
type TeamMemberChipProps = {
  memberName: string;
  memberPosition: string;
  memberBio?: string;
  memberAvatar?: string;
  chipHeight?: string;
};
export default function TeamMemberChip({
  memberName,
  memberPosition,
  memberBio,
  memberAvatar,
  chipHeight,
}: TeamMemberChipProps) {
  const { t, i18n } = useTranslation();
  return (
    <div
      className="team_member_chip-main_container"
      style={{ height: chipHeight }}
    >
      <div className="team_member_chip-name_position_bio_avatar_container">
        <div className="team_member_chip-avtar_container">
          <img src={memberAvatar} alt="" className="team_member_chip-avatar" />
        </div>
        <div className="team_member_chip-name_position_bio_container">
          <div className="team_member_chip-bio">{memberBio}</div>
            <div className="team_member_chip-name">{memberName}</div>
            <div className="team_member_chip-position">{memberPosition}</div>
        </div>
      </div>
    </div>
  );
}
