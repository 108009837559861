import { useEffect, useState } from "react";
import "./ComboBoxInput.css";
import {
    updateTheMessageBody,
    updateTheMessageContactInfo,
    updateTheMessageSubject,
  } from "../../redux/actions/sendmessage";
import { useDispatch, useSelector } from "react-redux";

type comboBoxProps = {
    label:string;
    items:string;//Lo vas a recibir como string y los elementos separados por comas ;)
    selectedItem?:string;
};
export default function ComboBoxInput({label,items,selectedItem}:comboBoxProps) {
    const dispatch:any = useDispatch();
    //-----------------------------------------------------------------------
    // STATE VARIABLES HERE
    //-----------------------------------------------------------------------
    const [comboBoxOptions,setComboBoxOptions] = useState<string[]>([])

    //-----------------------------------------------------------------------
    // USE EFFECTS HERE
    //-----------------------------------------------------------------------
    useEffect(()=>{
        let tempOptionsArray = items.split(",");
        setComboBoxOptions(tempOptionsArray);
    },[]);
    //-----------------------------------------------------------------------
    // HANDLERSA HERE
    //-----------------------------------------------------------------------
    const handleComboBoxInputSelect = (event:any) => {
        dispatch(updateTheMessageSubject(event.target.value));
    };

    //-----------------------------------------------------------
  // REDUX SELECTORS HERE
  //-----------------------------------------------------------
  const { messageSubject
     } = useSelector(
    (state: any) => state.sendMessageReducer
  );
    return(
        <div className="combo_box_input-main_container">
            <div className="combo_box_input-label">{label}</div>
            <select {...(selectedItem?{value:messageSubject}:{value:"Otros"})} name="" id="" className="combo_box_input-combo_box" onChange={handleComboBoxInputSelect}>
                {comboBoxOptions.map((option,index)=>(
                    <option key={index} value={option}>{option}</option>
                ))}
            </select>
        </div>
    );
}