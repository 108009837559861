import { useTranslation } from "react-i18next";
import "./MainMenu.css";
import { useNavigate } from "react-router-dom";
export default function MainMenu() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  //-------------------------------------------------------------------
  // HANDLERS HERE
  //-------------------------------------------------------------------
  const handleHomeButtonClick = () => {
    navigate("/");
  };
  const handleServicesButtonClick = ()=>{
    navigate("/services")
  }
  return (
    <div className="main_menu-container">
      <menu>
        <button className="main_menu_button" onClick={handleHomeButtonClick}>
          {t("inicio")}
        </button>
        <button className="main_menu_button" onClick={handleServicesButtonClick}>{t("servicios")}</button>
        {/* <button className="main_menu_button">{t("productos")}</button>
        <button className="main_menu_button">{t("quienesSomos")}</button>
        <button className="main_menu_button">{t("contactanos")}</button> */}
      </menu>
    </div>
  );
}
