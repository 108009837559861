import { createSlice } from "@reduxjs/toolkit";

interface sendMessageState {
  contactInfo: string; //Informacion de contacto. Esto en el frontend debes garantizar no te manden cascarita de piña
  messageSubject: string; //El asunto del mensaje a enviar
  messageBody: string; //El cuerpo del mensaje aenviar
  requestingMessageSend: boolean;
  successRequestingMessageSend: boolean;
  errorRequestingMessageSend: boolean;
  errorRequestingMessageSendData: {};
  contactMethodValid:boolean,
  messageBodyValid:boolean

}

const initialState: sendMessageState = {
  contactInfo: "",
  errorRequestingMessageSend: false,
  errorRequestingMessageSendData: {},
  messageBody: "",
  messageSubject: "",
  requestingMessageSend: false,
  successRequestingMessageSend: false,
  contactMethodValid:false,
  messageBodyValid:false
};

const sendMessage = createSlice({
  name: "SendMessage",
  initialState,
  reducers: {
    updateMessageContactInfo(state, action) {
      state.contactInfo = action.payload.messageContactInfo;
    },
    updateMessageSubject(state, action) {
      state.messageSubject = action.payload.messageSubject;
    },
    updateMessageBody(state, action) {
      state.messageBody = action.payload.messageBody;
    },
    updateContactMethodValid(state,action){
      state.contactMethodValid = action.payload.contactMethodValid;
    },
    updateMessageBodyValid(state,action){
      state.messageBodyValid = action.payload.messageBodyValid
    }
    //OJO TE FALTA EL RESTO DE LOS REDUCERS APRA CUANDO VAYAS A CONSUMIR LA API O LO QUE SEA QUE VAYAS A UTILIZAR PARA ENVIAR EL MENSAJE
  },
});

const { actions, reducer } = sendMessage;
const { updateMessageContactInfo, updateMessageSubject, updateMessageBody,updateContactMethodValid,updateMessageBodyValid } = actions;
export { updateMessageContactInfo, updateMessageSubject, updateMessageBody,updateContactMethodValid,updateMessageBodyValid };
export default reducer;
