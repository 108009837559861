import { useTranslation } from "react-i18next";
import ActionButton from "../ActionButton/ActionButton";
import "./ProductServiceChip.css"
/**Parametros que debe recibir:
 * knowMoreHandler: Es la URL para cuando le den al boton conoce mas.
 * contactUsHandler: ES el handler para cuando le den al ContactUs.
 * chipTitle: El titulo de la tarjeta.
 * chipDescription: L adescripcion de la tarjeta
 * chipLogo: El avatar o logo para utilizar en la tarjeta. Esto deberia tener un tamaño prestablecido esto lo veo later.
 */
type ProductServiceChipProps = {
    knowMoreHandler?:any;
    contactUsHandler?:any;
    chipTitle:string;
    chipDescription:string;
    chipLogo?:string;
};
export default function ProductServiceChip({chipTitle,chipDescription,chipLogo,contactUsHandler,knowMoreHandler}:ProductServiceChipProps) {
    const { t, i18n } = useTranslation();
    return(
        <div className="product_service_chip-main_container">
            <div className="product_service_chip-title_description_logo_container">
                <div className="product_service_chip-title_description_container">
                    <p className="product_service_chip-title">{chipTitle}</p>
                    <p className="product_service_chip-description">{chipDescription}</p>
                </div>
                <div className="product_service_chip-logo_container">
                    <img src={chipLogo} alt="" className="product_service_chip-logo"/>
                </div>
            </div>
            <div className="product_service_chip-action_buttons_container">
                <ActionButton backgroundColor="#FDBA5F" buttonText={t("productServiceChip_ConoceMas")} textColor="#000000" onClick={knowMoreHandler} />
                <ActionButton backgroundColor="#0E4DE3" buttonText={t("productServiceChip_Contactanos")} textColor="#FFFFFF" onClick={contactUsHandler}/>
            </div>
        </div>
    );
}