import { useTranslation } from "react-i18next";
import "./ContactForm.css";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTheMessageBody,
  updateTheMessageContactInfo,
  updateTheMessageSubject,
  updateTheMessageBodyValid
} from "../../redux/actions/sendmessage";
import ContactMethodInput from "../ContactMethodInput/ContactMethodInput";
import ComboBoxInput from "../ComboBoxInput/ComboBoxInput";
import ActionButton from "../ActionButton/ActionButton";
import emailjs from "@emailjs/browser"

//TODO: Cuando envias el mesane tienes que avisarle al usuario que el
//mensaje fue enviado de forma satisfactoria mediante un mensajito, una paloma
//algo. Y despues cerrar el cuadro de dialogo del mensaje.
type contactFormProps = {
  subject?:string|"Otro"|"Programación Web"|"Sistema Arduino"|"Asistencia Virtual"|"Dispositivos Móviles"|"Kronos Time Tracker"|"Búsqueda de Empleo"|"Partnership"|"Inversionista"
};
export default function ContactForm({subject}:contactFormProps) {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();

  const handleMessageBodyChange = (event: any) => {
    if(event.target.value.length !== 0)
    {
      dispatch(updateTheMessageBody(event.target.value));
      dispatch(updateTheMessageBodyValid(true))
    }else{
      dispatch(updateTheMessageBodyValid(false))
    }
  };

  const sendMessage = ()=>{
    let templateParams = {
      contact_info: contactInfo,
      contact_topic:messageSubject,
      message:messageBody
  
    };
    if(contactMethodValid===true && messageBodyValid===true){

      emailjs.send("service_eplf3de","template_s7ql75v",templateParams,"nKyMXfRB34XsRJV-H")
      .then(function(response) {
        console.log('SUCCESS!', response.status, response.text);
     }, function(error) {
        console.log('FAILED...', error);
     });
    }else{
      alert(t("contactForm_MessageError"))
    }
  };
  //-----------------------------------------------------------
  // REDUX SELECTORS HERE
  //-----------------------------------------------------------
  const { contactInfo, messageBody, messageSubject,contactMethodValid,
    messageBodyValid } = useSelector(
    (state: any) => state.sendMessageReducer
  );

  useEffect(()=>{
    if (subject) {
      dispatch(updateTheMessageSubject(subject))
    }
  },[])

  return (
    <div className="contact_form-main_container">
      <div className="contact_form-contact_method_subject_container">
        <ContactMethodInput />
        <ComboBoxInput
          items={t("contactForm_SubjectOptions")}
          label={t("contactForm_SubjectLabel")}
          
          {...(subject?{selectedItem:subject}:{selectedItem:"Otro"})}
        />
      </div>
      <div className="contact_form-message_container">
        <div className="contact_form-message_label">{t("contactForm_MessageLabel")}</div>
        <textarea className="contact_form-message_text" onChange={handleMessageBodyChange}></textarea>
      </div>
      <div className="contact_form_action_buttons_container">
        <ActionButton backgroundColor="#0E4DE3" buttonText={t("contactForm_SendMessage")} textColor="#FFFFFF" onClick={sendMessage}/>

        {/**El boton de adjuntar lo voy a comentar de momento porque el servicio de correos es free y no permite adjuntos grandes. */}
        {/* <ActionButton backgroundColor="#FFFFFF" buttonText={t("contactForm_AttachFile")} textColor="#0E4DE3" borderColor="#0E4DE3"/> */}
      </div>
    </div>
  );
}
