import { useTranslation } from "react-i18next";
import "./ServicesPageBannerSection.css";
import ActionButton from "../ActionButton/ActionButton";
import ServicesCarousel from "../ServicesCarousel/ServicesCarousel";
import { useDispatch } from "react-redux";
type servicesPageBannerProps = {
  servicesTabsReference:any;
};
export default function ServicesPageBannerSection({servicesTabsReference}:servicesPageBannerProps) {
  const { t, i18n } = useTranslation();
  //---------------------------------------------------------------------------
  // HANDLERS HERE
  //---------------------------------------------------------------------------
  const handleNuestrosProductosClick = () => {
    alert("YEYLIONGO");
  };
  const handleNuestrosServiciosClick = () => {
    alert("YEYLIONGO");
  };
  return (
    <div className="ServicesPageBannerSection--main_container">
      <div className="ServicesPageBannerSection--welcome_title_description_action_container">
        <p className="banner--title">{t("cta_section_title")}</p>
        <p className="ServicesPageBannerSection--welcome">{t("servicios")}</p>
      </div>
      <div className="ServicesPageBannerSection--carrusel_container">
        <ServicesCarousel servicesTabsReference={servicesTabsReference}/>
      </div>
    </div>
  );
}
