import { Carousel } from "react-carousel3";
import ProductServiceChip from "../ProductServiceChip/ProductServiceChip";
import TestimonialChip from "../TestimonialChip/TestimonialChip";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { useState } from "react";
import ContactForm from "../ContactForm/ContactForm";
import Modal from "../Modal/Modal";

export default function CTACarousel() {
  const style = {
    width: 297,
    height: 296,
  };
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  /**-------------------------------------------------------------------- */
  /** STATE VARIABLES HERE                                                */
  /**-------------------------------------------------------------------- */
  const [contactFormModalOpen, setContactFormModalOpen] =
    useState<boolean>(false);
  const [messageSubject, setMessageSubject] = useState("Otro");
  /**--------------------------------------------------------- */
  /** HANDLERS HERE                                            */
  /**--------------------------------------------------------- */
  const handleWebProgrammingKnowMoreClick = () => {
    navigate("/services/webprogramming");
  };
  const handleArduinoSystemKnowMoreClick = () => {
    navigate("/services/arduinosystems");
  };
  const handleVirtualAssistanceKnowMoreClick = () => {
    navigate("/services/virtualassistance");
  };
  const handleMobileDeviceKnowMoreClick = () => {
    navigate("/services/mobiledevices");
  };
  const handleContactFormClose = () => {
    setContactFormModalOpen(false);
  };

  const handleContactFormOpenVirtualAssistance = () => {
    setContactFormModalOpen(true);
    setMessageSubject("Asistencia Virtual");
  };
  const handleContactFormOpenWebProgramming = () => {
    setContactFormModalOpen(true);
    setMessageSubject("Programación Web");
  };
  const handleContactFormOpenArduinoSystem = () => {
    setContactFormModalOpen(true);
    setMessageSubject("Sistema Arduino");
  };
  const handleContactFormOpenMobileDevices = () => {
    setContactFormModalOpen(true);
    setMessageSubject("Dispositivos Móviles");
  };
  return (
    <div>
      <Carousel
        height={500}
        width={600}
        yOrigin={0}
        yRadius={0}
        autoPlay={true}
      >
        <div key={1} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_ProgramacionWebDescripcion")}
            chipTitle={t("landingPage_ProgramacionWeb")}
            chipLogo="web develop.jpg"
            knowMoreHandler={handleWebProgrammingKnowMoreClick}
            contactUsHandler={handleContactFormOpenWebProgramming}
          />
        </div>
        <div key={5} style={style}>
          <ProductServiceChip
            chipDescription={t("productsSection_KronosDescription")}
            chipTitle={t("productsSection_Kronos")}
            chipLogo="KRONOS LOGO.png"
          />
        </div>
        <div key={6} style={style}>
          <TestimonialChip
            memberName="Susanita Perez Sotolongo"
            memberPosition="CEO, Good Morning Designs"
            memberAvatar="RANDOM_AVATAR 3.jpg"
            memberBio="Trabajar con esta gente fue la OSTIA!!!"
            chipHeight="230px"
          />
        </div>
        <div key={2} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_SistemaArduinoDescripcion")}
            chipTitle={t("landingPage_SistemaArduino")}
            chipLogo="arduino_system.jpg"
            knowMoreHandler={handleArduinoSystemKnowMoreClick}
            contactUsHandler={handleContactFormOpenArduinoSystem}
          />
        </div>
        <div key={7} style={style}>
          <TestimonialChip
            memberName="Yoana Hernandez Mesa"
            memberPosition="CEO, Rosita Fresita S.A."
            memberAvatar="RANDOM_AVATAR 2.jpg"
            memberBio="Me ayudaron con el sitio web de mi emprendimiento. Su trabajo fue excelente!"
            chipHeight="230px"
          />
        </div>
        <div key={3} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_AsistenciaVirtualDescripcion")}
            chipTitle={t("landingPage_AsistenciaVirtual")}
            chipLogo="virtual_assistant.jpg"
            knowMoreHandler={handleVirtualAssistanceKnowMoreClick}
            contactUsHandler={handleContactFormOpenVirtualAssistance}
          />
        </div>
        <div key={8} style={style}>
          <TestimonialChip
            memberName="Javier Riquimbili Moya"
            memberPosition="Colaborador"
            memberAvatar="RANDOM_AVATAR 1.jpg"
            memberBio="Excelentes en lo que hacen! El trabajo fue entregado en el tiempo pactqado y con la calidad requerida. Fue un placer contar con sus recomendaciones para la mejora del producto."
            chipHeight="230px"
          />
        </div>
        <div key={4} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_DispositivosMovilesDescripcion")}
            chipTitle={t("landingPage_DispositivosMoviles")}
            chipLogo="mobile_devices.jpg"
            knowMoreHandler={handleMobileDeviceKnowMoreClick}
            contactUsHandler={handleContactFormOpenMobileDevices}
          />
        </div>
      </Carousel>
      <Modal
        children={<ContactForm subject={messageSubject} />}
        isOpen={contactFormModalOpen}
        onClose={handleContactFormClose}
      />
    </div>
  );
}
