import { createAction } from "@reduxjs/toolkit";
import {
  updateMessageContactInfo as updateMessageContactInfoSlice,
  updateMessageSubject as updateMessageSubjectSlice,
  updateMessageBody as updateMessageBodySlice,
  updateContactMethodValid as updateContactMethodValidSlice,
  updateMessageBodyValid as updateMessageBodyValidSlice
} from "../slices/sendmessage";

export const updateMessageContactInfo = createAction("UPDATE_MESSAGE_CONTACT_INFO");
export const updateMessageSubject = createAction("UPDATE_MESSAGE_SUBJECT");
export const updateMessageBody = createAction("UPDATE_MESSAGE_BODY");
export const updateContactMethodValid = createAction("UPDATE_CONTACT_METHOD_VALID")
export const updateMessageBodyValid = createAction("UPDATE_MESSAGE_BODY_VALID")

export const updateTheMessageContactInfo = (messageContactInfo:string)=> async (dispatch:any)=>{
    dispatch(updateMessageContactInfoSlice({messageContactInfo}))
}

export const updateTheMessageSubject = (messageSubject:string)=> async (dispatch:any)=>{
    dispatch(updateMessageSubjectSlice({messageSubject}))
}

export const updateTheMessageBody = (messageBody:string)=> async (dispatch:any)=>{
    dispatch(updateMessageBodySlice({messageBody}))
}

export const updateTheContactMethodValid = (contactMethodValid:boolean)=> async (dispatch:any)=>{
    dispatch(updateContactMethodValidSlice({contactMethodValid}))
}

export const updateTheMessageBodyValid = (messageBodyValid:boolean)=>async(dispatch:any)=>{
    dispatch(updateMessageBodyValidSlice({messageBodyValid}));
}