import { t } from "i18next";
import ProductServiceChip from "../ProductServiceChip/ProductServiceChip";
import "./ServicesSection.css";
import { useNavigate } from "react-router";

//TODO: El boton de la ficha deproducto que dice CONTACTANOS debe decir otra cosa y llevarte directamente a
// la pagina donde tienes alojado a Kronos Time Tracker

export default function ServicesSection() {
  const navigate = useNavigate();
  /**--------------------------------------------------------- */
  /** HANDLERS HERE                                            */
  /**--------------------------------------------------------- */
  const handleWebProgrammingKnowMoreClick = () => {
    navigate("/services/webprogramming");
  };
  const handleArduinoSystemKnowMoreClick = () => {
    navigate("/services/arduinosystems");
  };
  const handleVirtualAssistanceKnowMoreClick = () => {
    navigate("/services/virtualassistance");
  };
  const handleMobileDeviceKnowMoreClick = () => {
    navigate("/services/mobiledevices");
  };
  return (
    <div className="services_section-main_container">
      <div className="services_section-title_container">
        <p className="services_section-title">
          {t("servicesSection_Servicios")}
        </p>
      </div>
      <div className="services_section-heading_container">
        <p className="services_section-heading">
          {t("servicesSection_QuePOdemosHacerPorTi")}
        </p>
      </div>
      <div key={10} className="services_section-product_service_chip_container">
        <div key={0}>
          <ProductServiceChip
            chipDescription={t("landingPage_ProgramacionWebDescripcion")}
            chipTitle={t("landingPage_ProgramacionWeb")}
            chipLogo="web develop.jpg"
            knowMoreHandler={handleWebProgrammingKnowMoreClick}
          />
        </div>
        <div key={1}>
          <ProductServiceChip
            chipDescription={t("landingPage_SistemaArduinoDescripcion")}
            chipTitle={t("landingPage_SistemaArduino")}
            chipLogo="arduino_system.jpg"
            knowMoreHandler={handleArduinoSystemKnowMoreClick}
          />
        </div>
        <div key={2}>
          <ProductServiceChip
            chipDescription={t("landingPage_AsistenciaVirtualDescripcion")}
            chipTitle={t("landingPage_AsistenciaVirtual")}
            chipLogo="virtual_assistant.jpg"
            knowMoreHandler={handleVirtualAssistanceKnowMoreClick}
          />
        </div>
        <div key={3}>
          <ProductServiceChip
            chipDescription={t("landingPage_DispositivosMovilesDescripcion")}
            chipTitle={t("landingPage_DispositivosMoviles")}
            chipLogo="mobile_devices.jpg"
            knowMoreHandler={handleMobileDeviceKnowMoreClick}
          />
        </div>
      </div>
    </div>
  );
}
