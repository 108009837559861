import "./PayPalMeButton.css"

export default function PayPalMeButton() {
    return(
        <div>
            <a href="https://www.paypal.com/paypalme/automaticmen" target="_blank">
                <img src="logotipo_paypal_pagos.png" alt="" style={{borderRadius:"10px"}}/>
            </a>
        </div>
    );
}