import { useTranslation } from "react-i18next";
import ActionButton from "../../components/ActionButton/ActionButton";
import "./ServiceDetailsArduinoSystems.css";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";

export default function ServiceDetailsWebProgramming() {
  const { t, i18n } = useTranslation();
  /**----------------------------------------------------------------------------- */
  /**STATE VARIABLES HERE                                                          */
  /**----------------------------------------------------------------------------- */
  const [showContactForm, setShowContactForm] = useState<boolean>(false);

  /**----------------------------------------------------------------------------- */
  /**HANDLERS HERE                                                                 */
  /**----------------------------------------------------------------------------- */
  const handleCloseContactFormModalClose = () => {
    setShowContactForm(false);
  };

  const handleContactUsClick = () => {
    setShowContactForm(true);
  };
  return (
    <div className="ServiceDetailsWebProgramming--main_container">
      <div className="ServiceDetailsWebProgramming--title_description_container">
        <div className="section_title">
          {t("ServiceDetailsArduinoSystems-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** DEFINICION DE REQUISITOS */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Requirements-Definition-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Requirements-Definition-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>

        {/** Seleccion de Placa Arduino */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Board-Selection-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Board-Selection-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** DISEÑO DEL CIRCUITO */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Circuit-Design-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Circuit-Design-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** PROGRAMACION ARDUINO IDE */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-IDE-Programming-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-IDE-Programming-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>

        {/** DESARROLLO DEL CODIGO */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Code-Develop-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Code-Develop-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** PRUEBAS Y DEPURACION */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Debug-Testing-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Debug-Testing-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** INTEGRACION DE COMPOENENTS */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Components-Integration-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Components-Integration-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>

        {/** OPTIMIZACION DEL CODIGO */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Code-Optmization-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Code-Optmization-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** DOCUMENTACION*/}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Documentation-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Documentation-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        {/** IMPLEMENTACION Y PUESTA N E MARCHA */}
        <div className="section_sub_title">
          {t("ServiceDetailsArduinoSystems-Implementation-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsArduinoSystems-Implementation-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}

          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>
        {/**------------------------------------------------- */}

      </div>
      <div className="ServiceDetailsWebProgramming--logo_container">
        <img
          className="ServiceDetailsWebProgramming--logo"
          src="arduino_system.jpg"
          alt=""
        />
      </div>

      {/**CONTACT FORM MODAL */}
      <div className="ServicesDetailWeb--contact_form_modal_container">
        <Modal
          isOpen={showContactForm}
          onClose={handleCloseContactFormModalClose}
          children={<ContactForm subject="Sistema Arduino"/>}
        />
      </div>
    </div>
  );
}
