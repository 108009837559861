import { useTranslation } from "react-i18next";
import "./ProductsSection.css";
import ProductServiceChip from "../ProductServiceChip/ProductServiceChip";

export default function ProductsSection() {
  const { t, i18n } = useTranslation();
  return (
    <div className="products_section-main_container">
      <div className="products_section-title_description_container">
        <div className="products_section-title">
          {t("productsSectionTitle")}
        </div>
        <div className="products_section-description">
          {t("productsSectionDescription")}
        </div>
      </div>
      <div className="products_section-products_container">
        <ProductServiceChip
          chipDescription={t("productsSection_KronosDescription")}
          chipTitle={t("productsSection_Kronos")}
          chipLogo="KRONOS LOGO.png"
        />
      </div>
    </div>
  );
}
