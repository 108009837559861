import { useTranslation } from "react-i18next";
import "./LetsStartSection.css"
import ActionButton from "../ActionButton/ActionButton";
import ContactForm from "../ContactForm/ContactForm";

export default function LetsStartSection() {
    const { t, i18n } = useTranslation();
    return(
        <div className="lets_start_section-main_container">
            <div className="lets_start_section-section_name">{t("letsStartSection_SectionName")}</div>
            <div className="lets_start_section-section_content_contact_form_container">
                <div className="lets_start_section-section_content_container">
                    <div className="lets_start_section-section_heading">{t("letsStartSection_SectionHeading")}</div>
                    <div className="lets_start_section-section_description">{t("letsStartSection_SectionDescription")}</div>
                    <ActionButton backgroundColor="#0E4DE3" buttonText={t("letsStartSection_ContacUs")} textColor="#FFFFFF"/>
                </div>
                <div className="lets_start_section-contact_form_container">
                    <ContactForm/>
                </div>
            </div>
        </div>
    );
}