import { useTranslation } from "react-i18next";
import ActionButton from "../ActionButton/ActionButton";
import "./MissionVisionSection.css";

export default function MissionVisionSection() {
  const { t, i18n } = useTranslation();
  return (
    <div className="mission_vision_section-main_container">
      <div className="mission_vision_section-title_description_container">
        <div className="mission_vision_section-section_name">
          {t("missionVisionSectionName")}
        </div>
        <div className="mission_vision_section-section_title">
          {t("missionVisionSectionTitle")}
        </div>
        <div className="mission_vision_section-section_description">
          {t("missionVisionSectionDescription")}
        </div>
        <div className="mission_vision_section-action_button_container">
          <ActionButton
            backgroundColor="#0E4DE3"
            buttonText={t("quienesSomos")}
            textColor={"#FFFFFF"}
          />
        </div>
      </div>
      <div className="mission_vision_section-logo_container">
        <img
          className="mission_vision_section-logo"
          src="AUTOMATIC MEN LOGO.png"
          alt=""
        />
      </div>
    </div>
  );
}
