import { useTranslation } from "react-i18next";
import "./OurTeamSection.css"
import TeamMemberChip from "../TeamMemberChip/TeamMemberChip";
import OurTeamCarousel from "../OurTeamCarousel/OurTeamCarousel";
import CustomCarousel from "../CustomCarousel/CustomCarousel";
import { Carousel } from "react-carousel3";
import SimpleCarousel from "../SimpleCarousel/SimpleCarousel";

export default function OurTeamSection() {
    const { t, i18n } = useTranslation()
    const data = [
        <div>
          <TeamMemberChip
            memberName="Jorge Aguilera Perez"
            memberPosition="CEO"
            memberAvatar="JAP_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Milagros Perez Herrada"
            memberPosition="Representante Legal"
            memberAvatar="MPH_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Miguel Portieles Perez"
            memberPosition="Colaborador"
            memberAvatar="MPP_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Frank Aranda Carret"
            memberPosition="Colaborador"
            memberAvatar="FAC_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Amado Galiano Paderni"
            memberPosition="Colaborador"
            memberAvatar="AGP_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Yadian Santos Leyva"
            memberPosition="Colaborador"
            memberAvatar="YSL_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Daniel Gonzalez Cueto"
            memberPosition="Colaborador"
            memberAvatar="DGC_AVATAR_1_500x500.png"
          />
        </div>,
        <div>
          <TeamMemberChip
            memberName="Yanisbel Torres Rodríguez"
            memberPosition="Colaborador"
            memberAvatar="YTR_AVATAR_1_500x500.png"
          />
        </div>,
      ];
    return(
        <div className="our_team_section-main_container">
            <div className="our_team_section-title">
                {t("ourTeamSection_Title")}
            </div>
            <div className="our_team_section-team_members_container">
                <TeamMemberChip memberName="Jorge Aguilera Perez" memberPosition="CEO" memberAvatar="JAP_AVATAR_1_500x500.png"/>
                <TeamMemberChip memberName="Milagros Perez Herrada" memberPosition="Representante Legal" memberAvatar="MPH_AVATAR_1_500x500.png"/>
                <TeamMemberChip memberName="Miguel Portieles Perez" memberPosition="Colaborador" memberAvatar="MPP_AVATAR_1_500x500.png"/>
                <TeamMemberChip memberName="Frank Aranda Carret" memberPosition="Colaborador" memberAvatar="FAC_AVATAR_1_500x500.png"/>
                <TeamMemberChip memberName="Amado Galiano Paderni" memberPosition="Colaborador" memberAvatar="AGP_AVATAR_1_500x500.png"/>
                <TeamMemberChip memberName="Yadian Santos Leyva" memberPosition="Colaborador" memberAvatar="YSL_AVATAR_1_500x500.png"/>
                <TeamMemberChip memberName="Daniel Gonzalez Cueto" memberPosition="Colaborador" memberAvatar="DGC_AVATAR_1_500x500.png" />
                <TeamMemberChip memberName="Yanisbel Torres Rodríguez" memberPosition="Colaborador" memberAvatar="YTR_AVATAR_1_500x500.png" />
            </div>
            <div className="our_team_section-team_members_carousel_container">
                {/* <OurTeamCarousel/> */}
            </div>
            <div className="our_team_section-team_members_carousel_container">
                <SimpleCarousel autoPlay={true} interval={3000} items={data}/>
            </div>
        </div>
    );
}