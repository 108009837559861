import { useTranslation } from "react-i18next";
import CTASection from "../../components/CTASection/CTASection";
import Header from "../../components/Header/Header";
import MissionVisionSection from "../../components/MissionVisionSection/MissionVisionSection";
import ProductServiceChip from "../../components/ProductServiceChip/ProductServiceChip";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import OurTeamSection from "../../components/OurTeamSection/OurTeamSection";
import LetsStartSection from "../../components/LetsStartSection/LetsStartSection";
import ExperiencesSection from "../../components/ExperiencesSection/ExperiencesSection";
import FooterSection from "../../components/FooterSection/FooterSection";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import TestimonialChip from "../../components/TestimonialChip/TestimonialChip";
import { useEffect, useRef } from "react";
import handleScroll from "../../utils/handleScroll";


//TODO Conectar para que los chips del carrusel hagan lo que tienen que hacer cuando des click :)
//TODO: Poner algun tipo de animacion en la esquina inferior derecha/centro que indique la pagina tiene mas contenido. Y cuando se llegue al final de la pagina que desaparezca indicando que no hay mas na.

export default function LandingPage() {
  const { t, i18n } = useTranslation();
  const landingPageTopRef:any = useRef();

  useEffect(()=>{
    handleScroll(landingPageTopRef.current)
  },[])
  return (
    <div ref={landingPageTopRef} className="landing_page-main_container">
      <div className="header_cta_section-container">
        <CTASection />
      </div>
      <MissionVisionSection />
      <ServicesSection />
      <ProductsSection />
      <OurTeamSection />
      <LetsStartSection />
      <ExperiencesSection />
      <FooterSection />
      <div className="carruselito_container">

      
      </div>

    </div>
  );
}
