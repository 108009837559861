import { useTranslation } from "react-i18next";
import CTASection from "../../components/CTASection/CTASection";
import Header from "../../components/Header/Header";
import MissionVisionSection from "../../components/MissionVisionSection/MissionVisionSection";
import ProductServiceChip from "../../components/ProductServiceChip/ProductServiceChip";
import ServicesSection from "../../components/ServicesSection/ServicesSection";
import ProductsSection from "../../components/ProductsSection/ProductsSection";
import OurTeamSection from "../../components/OurTeamSection/OurTeamSection";
import LetsStartSection from "../../components/LetsStartSection/LetsStartSection";
import ExperiencesSection from "../../components/ExperiencesSection/ExperiencesSection";
import FooterSection from "../../components/FooterSection/FooterSection";
import CustomCarousel from "../../components/CustomCarousel/CustomCarousel";
import TestimonialChip from "../../components/TestimonialChip/TestimonialChip";
import ServicesPageBannerSection from "../../components/ServicesPageBannerSection/ServicesPageBannerSection";
import ServiceDetailsWebProgramming from "../../services/ServiceDetailsWebProgramming/ServiceDetailsWebProgramming";
import ServiceDetailsArduinSystems from "../../services/ServiceDetailsArduinoSystems/ServiceDetailsArduinoSystems";
import Tabs from "../../components/Tabs/Tabs";
import ServiceDetailsMobileDevices from "../../services/ServiceDetailsMobileDevices/ServiceDetailsMobileDevices";
import ServiceDetailsVirtualAssistance from "../../services/ServiceDetailsVirtualAssistance/ServiceDetailsVirtualAssistance";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef } from "react";
import handleScroll from "../../utils/handleScroll";
import { useParams } from "react-router";
import { updateTheActiveServiceTabIndex } from "../../redux/actions/global";
import { number, string } from "yargs";

//TODO: Poner algun tipo de animacion en la esquina inferior derecha/centro que indique la pagina tiene mas contenido. Y cuando se llegue al final de la pagina que desaparezca indicando que no hay mas na.

export default function ServicesPage() {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const serviceTabsRef: any = useRef();
  const servicesPageTopRef: any = useRef();
  let { section } = useParams();

  useEffect(() => {
    switch (section) {
      case "webprogramming":
        dispatch(updateTheActiveServiceTabIndex(0));
        handleScroll(serviceTabsRef.current);
        break;
      case "arduinosystems":
        dispatch(updateTheActiveServiceTabIndex(1));
        handleScroll(serviceTabsRef.current);
        break;
      case "virtualassistance":
        dispatch(updateTheActiveServiceTabIndex(2));
        handleScroll(serviceTabsRef.current);
        break;
      case "mobiledevices":
        dispatch(updateTheActiveServiceTabIndex(3));
        handleScroll(serviceTabsRef.current);
        break;

      default:
        dispatch(updateTheActiveServiceTabIndex(0));
        handleScroll(servicesPageTopRef.current);
        break;
    }
  }, []);
  return (
    <div ref={servicesPageTopRef} className="landing_page-main_container">
      <div className="header_cta_section-container">
        <ServicesPageBannerSection servicesTabsReference={serviceTabsRef} />
      </div>
      <div ref={serviceTabsRef}>
        <Tabs
          activeTabNumber={0}
          tabsContents={[
            <ServiceDetailsWebProgramming />,
            <ServiceDetailsArduinSystems />,
            <ServiceDetailsVirtualAssistance />,
            <ServiceDetailsMobileDevices />,
          ]}
          tabsNames={[
            "Programacion Web",
            "Sistemas Arduino",
            "Asistencia Virtual",
            "Dispositivos Moviles",
          ]}
        />
        <FooterSection />
      </div>
    </div>
  );
}
