import { Carousel } from "react-carousel3";
import ProductServiceChip from "../ProductServiceChip/ProductServiceChip";
import TestimonialChip from "../TestimonialChip/TestimonialChip";
import { useTranslation } from "react-i18next";
import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  updateTheActiveServiceTabIndex,
  updateTheServicesCarouselRunning,
} from "../../redux/actions/global";
import handleScroll from "../../utils/handleScroll";
import Modal from "../Modal/Modal";
import ContactForm from "../ContactForm/ContactForm";
import WebDevelopImage from "../../assets/web develop.jpg"
import ArduinoSystemImage from "../../assets/arduino_system.jpg"
import VirtualAssistanImage from "../../assets/virtual_assistant.jpg"
import MobileDeviceImage from "../../assets/mobile_devices.jpg"
type servicesCarouselProps = {
  servicesTabsReference: any;
};
export default function ServicesCarousel({
  servicesTabsReference,
}: servicesCarouselProps) {
  const style = {
    width: 297,
    height: 296,
  };
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();

  //-----------------------------------------------------------
  // REDUX SELECTORS HERE
  //-----------------------------------------------------------
  const { servicesCarouselRunning } = useSelector(
    (state: any) => state.globalReducer
  );
  /**-------------------------------------------------------------------- */
  /** STATE VARIABLES HERE                                                */
  /**-------------------------------------------------------------------- */
  const [contactFormModalOpen,setContactFormModalOpen] = useState<boolean>(false);
  const [messageSubject,setMessageSubject] = useState("Otro")
  /**-------------------------------------------------------------------- */
  /** HANDLERS HERE                                                       */
  /**-------------------------------------------------------------------- */

  const webProgrammingKnowMoreHandler = () => {
    handleScroll(servicesTabsReference.current);
    dispatch(updateTheActiveServiceTabIndex(0));
  };
  const arduinoSystemKnowMoreHandler = () => {
    dispatch(updateTheActiveServiceTabIndex(1));
    handleScroll(servicesTabsReference.current);
  };
  const virtualAssistanceKnowMoreHandler = () => {
    dispatch(updateTheActiveServiceTabIndex(2));
    handleScroll(servicesTabsReference.current);
  };
  const mobileDevicesKnowMoreHandler = () => {
    dispatch(updateTheActiveServiceTabIndex(3));
    handleScroll(servicesTabsReference.current);
  };

  const handleContactFormClose = ()=>{
    setContactFormModalOpen(false)
  }

  const handleContactFormOpenVirtualAssistance = ()=>{
    setContactFormModalOpen(true)
    setMessageSubject("Asistencia Virtual")
    
  }
  const handleContactFormOpenWebProgramming = ()=>{
    setContactFormModalOpen(true)
    setMessageSubject("Programación Web")
    
  }
  const handleContactFormOpenArduinoSystem = ()=>{
    setContactFormModalOpen(true)
    setMessageSubject("Sistema Arduino")
    
  }
  const handleContactFormOpenMobileDevices = ()=>{
    setContactFormModalOpen(true)
    setMessageSubject("Dispositivos Móviles")
    
  }

  return (
    <div>
      <Carousel
        height={300}
        width={600}
        yOrigin={0}
        yRadius={100}
        autoPlay={false}
      >
        <div key={1} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_ProgramacionWebDescripcion")}
            chipTitle={t("landingPage_ProgramacionWeb")}
            chipLogo={WebDevelopImage}
            knowMoreHandler={webProgrammingKnowMoreHandler}
            contactUsHandler={handleContactFormOpenWebProgramming}
          />
        </div>
        <div key={2} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_SistemaArduinoDescripcion")}
            chipTitle={t("landingPage_SistemaArduino")}
            chipLogo={ArduinoSystemImage}
            knowMoreHandler={arduinoSystemKnowMoreHandler}
            contactUsHandler={handleContactFormOpenArduinoSystem}
          />
        </div>
        <div key={3} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_AsistenciaVirtualDescripcion")}
            chipTitle={t("landingPage_AsistenciaVirtual")}
            chipLogo={VirtualAssistanImage}
            knowMoreHandler={virtualAssistanceKnowMoreHandler}
            contactUsHandler={handleContactFormOpenVirtualAssistance}
          />
        </div>
        <div key={4} style={style}>
          <ProductServiceChip
            chipDescription={t("landingPage_DispositivosMovilesDescripcion")}
            chipTitle={t("landingPage_DispositivosMoviles")}
            chipLogo={MobileDeviceImage}
            knowMoreHandler={mobileDevicesKnowMoreHandler}
            contactUsHandler={handleContactFormOpenMobileDevices}
          />
        </div>
      </Carousel>

      <Modal children={<ContactForm subject={messageSubject}/>} isOpen={contactFormModalOpen} onClose={handleContactFormClose}/>
    </div>
  );
}
