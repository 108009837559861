import { createAction } from "@reduxjs/toolkit";
import {
    updateServicesCarouselRunning as updateServicesCarouselRunningSlice,
    updateActiveServiceTabIndex as updateActiveServiceTabIndexSlice
} from "../slices/global";

export const updateServicesCarouselRunning = createAction("UPDATE_SERVICES_CAROUSEL_RUNNING");

export const updateTheServicesCarouselRunning = (carouselRunning:boolean)=> async (dispatch:any)=>{
    dispatch(updateServicesCarouselRunningSlice({carouselRunning}))
}

export const updateTheActiveServiceTabIndex = (updateActiveServiceTabIndex:number)=> async (dispatch:any)=>{
    dispatch(updateActiveServiceTabIndexSlice({updateActiveServiceTabIndex}))
}