import { useTranslation } from "react-i18next";
import "./CTASection.css";
import ActionButton from "../ActionButton/ActionButton";
import CTACarousel from "../CTACarousel/CTACarousel";
import { useNavigate } from "react-router";
export default function CTASection() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  //---------------------------------------------------------------------------
  // HANDLERS HERE
  //---------------------------------------------------------------------------
  const handleNuestrosProductosClick = () => {
    alert("CLICK AQUI DEBE IR PARA LA PAGINA DE PRODUCTOS");
  };
  const handleNuestrosServiciosClick = () => {
    navigate("/services")
  };
  return (
    <div className="cta_section-main_container">
      <div className="cta_section-welcome_title_description_action_container">
        <p className="cta_section-welcome">{t("bienvenido")}</p>
        <p className="cta_secttion-title">{t("cta_section_title")}</p>
        <p className="cta_secttion-description">
          {t("cta_secttion-description")}
        </p>
        <div style={{ display: "flex", justifyContent: "flex-start" }}>
          <ActionButton
            backgroundColor="#FDBA5F"
            buttonText={t("nuestrosProductos")}
            textColor="#000000"
            onClick={handleNuestrosProductosClick}
          />
          <ActionButton
            backgroundColor="#FDBA5F"
            buttonText={t("nuestrosServicios")}
            textColor="#000000"
            onClick={handleNuestrosServiciosClick}
          />
        </div>
      </div>
      <div className="cta_section-carrusel_container">
        <CTACarousel/>
      </div>
    </div>
  );
}
