// src/Carousel.js
import React, { useEffect, useState } from "react";
import TeamMemberChip from "../TeamMemberChip/TeamMemberChip";
import "./SimpleCarousel.css";

type SimpleCarouselProps = {items:any, autoPlay:any, interval:any}
const SimpleCarousel = ({items,autoPlay,interval}:SimpleCarouselProps) => {
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    let intervalId:any;

    if (autoPlay) {
      intervalId = setInterval(() => {
        setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
      }, interval);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoPlay, interval, items]);

  const handlePrev = () => {
    setActiveIndex((prevIndex) => (prevIndex - 1 + items.length) % items.length);
  };

  const handleNext = () => {
    setActiveIndex((prevIndex) => (prevIndex + 1) % items.length);
  };

  return (
    <div className="carousel">
      <button onClick={handlePrev}>
        <span className="material-icons">arrow_left</span>
      </button>
      <div>{items[activeIndex]}</div>
      <button onClick={handleNext}>
        <span className="material-icons">arrow_right</span>
      </button>
    </div>
  );
};

export default SimpleCarousel;
