import "./Modal.css";

type ModalProps = { isOpen: boolean; onClose: any; children: any };
export default function Modal({ isOpen, onClose, children }: ModalProps) {
  const handleClose = () => {
    onClose();
  };

  return (
    <>
      {isOpen && (
        <div className="Modal--overlay">
          <div className="Modal--modal">
            {children}
            <span
              style={{ cursor: "pointer", height: "fit-content" }}
              className="material-icons"
              onClick={handleClose}
            >
              close
            </span>
          </div>
        </div>
      )}
    </>
  );
}
