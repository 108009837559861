import { BrowserRouter, Route, Routes } from "react-router-dom";
import LandingPage from "../views/LandingPage/LandingPage";
import Header from "../components/Header/Header";
import ServicesPage from "../views/ServicesPage/ServicesPage";

const RoutesComponent = () => {
  return (
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path="/" element={<LandingPage/>} />
        <Route path="/services" element={<ServicesPage/>}>
          <Route path=":section" element={<ServicesPage/>} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
};

export default RoutesComponent;
