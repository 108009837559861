import { useState } from "react";
import "./ActionButton.css"
type ActionButtonProps = {
    buttonText:string;
    textColor:string;
    backgroundColor:string;
    onClick?:any;
    borderColor?:string;
}
export default function ActionButton({buttonText,backgroundColor,onClick,textColor,borderColor}:ActionButtonProps) {
    //-------------------------------------------------------------------------------
    // STATE VARIABLES HERE
    //-------------------------------------------------------------------------------
    const [actionButtonMouseOver,setActionButtonMouseOver] = useState<Boolean>(false);

    //-------------------------------------------------------------------------------
    // HANDLERS HERE
    //-------------------------------------------------------------------------------
    const handleActionButtonMouseEnter = ()=>{
        setActionButtonMouseOver(true);
    };
    const handleActionButtonMouseLeave = ()=>{
        setActionButtonMouseOver(false);
    };

    //-------------------------------------------------------------------------------
    // OTHERS
    //-------------------------------------------------------------------------------
    const chevronStyle = (actionButtonMouseOver:Boolean)=>{
        return {
            color:actionButtonMouseOver?textColor:backgroundColor
        };
    }
    const buttonStyle = ()=>{
        return{
            color:textColor,
            backgroundColor:backgroundColor,
        }
    };
    const mainContainerStyle = ()=>{
        return {
            backgroundColor:backgroundColor,
            border:borderColor? "1px":"0px",
            borderStyle:borderColor? "solid":"none",
            borderColor: borderColor?borderColor:"",
        }
    };

    return(
        <div className="action_button-container" style={mainContainerStyle()} onClick={onClick} onMouseEnter={handleActionButtonMouseEnter} onMouseLeave={handleActionButtonMouseLeave}>

        <button className="action_button" style={buttonStyle()} >{buttonText} </button>
        <span className={`material-icons ${actionButtonMouseOver?"chevron_hover":"chevron"}`} style={chevronStyle(actionButtonMouseOver)} >
        chevron_right
        </span>
        </div>
    );
}