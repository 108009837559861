import { useTranslation } from "react-i18next";
import ActionButton from "../../components/ActionButton/ActionButton";
import "./ServiceDetailsVirtualAssistance.css";
import ContactForm from "../../components/ContactForm/ContactForm";
import { useState } from "react";
import Modal from "../../components/Modal/Modal";

export default function ServiceDetailsVirtualAssistance() {
  const { t, i18n } = useTranslation();
  /**----------------------------------------------------------------------------- */
  /**STATE VARIABLES HERE                                                          */
  /**----------------------------------------------------------------------------- */
  const [showContactForm, setShowContactForm] = useState<boolean>(false);

  /**----------------------------------------------------------------------------- */
  /**HANDLERS HERE                                                                 */
  /**----------------------------------------------------------------------------- */
  const handleCloseContactFormModalClose = () => {
    setShowContactForm(false);
  };

  const handleContactUsClick = () => {
    setShowContactForm(true);
  };
  return (
    <div className="ServiceDetailsWebProgramming--main_container">
      <div className="ServiceDetailsWebProgramming--title_description_container">
        <div className="section_title">
          {t("ServiceDetailsVirtualAssistance-Title")}
        </div>
        <div className="section_paragraph">
          {t("ServiceDetailsVirtualAssistance-Description")
            .split("\n")
            .map((paragraph: any) => (
              <p>{paragraph}</p>
            ))}
        </div>

        <div className="section_paragraph">
          <ul>
            {t("ServiceDetailsVirtualAssistance-Expertise-Fields")
              .split("\n")
              .map((element: any) => (
                <li>{element}</li>
              ))}
          </ul>
          <div className="section_paragraph">
            {t("ServiceDetailsVirtualAssistance-Summary")
              .split("\n")
              .map((paragraph: any) => (
                <p>{paragraph}</p>
              ))}
          </div>
          <div className="ServiceDetailsWebProgramming--action_button_container">
            <ActionButton
              backgroundColor="#0E4DE3"
              buttonText={t("ServiceDetailsWebProgramming-Contact-Button")}
              textColor={"#FFFFFF"}
              onClick={handleContactUsClick}
            />
          </div>
        </div>
      </div>
      <div className="ServiceDetailsWebProgramming--logo_container">
        <img
          className="ServiceDetailsWebProgramming--logo"
          src="virtual_assistant.jpg"
          alt=""
        />
      </div>

      {/**CONTACT FORM MODAL */}
      <div className="ServicesDetailWeb--contact_form_modal_container">
        <Modal
          children={<ContactForm subject="Asistencia Virtual"/>}
          isOpen={showContactForm}
          onClose={handleCloseContactFormModalClose}
        />
      </div>
    </div>
  );
}
