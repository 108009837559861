import { useEffect, useRef, useState } from "react";
import "./Tabs.css";
import { useDispatch, useSelector } from "react-redux";
import { updateTheActiveServiceTabIndex } from "../../redux/actions/global";
import handleScroll from "../../utils/handleScroll";

type TabsProps = {
  tabsContents: any[];
  tabsNames: string[];
  activeTabNumber:number;
};
export default function Tabs({ tabsContents, tabsNames,activeTabNumber }: TabsProps) {
  const [activeTab, setActiveTab] = useState(tabsNames[0]);
  const [activeTabIndex, setActiveTabIndex] = useState<number>(activeTabNumber);
  let tabsContainerRef:any = useRef()
//-----------------------------------------------------------
  // REDUX SELECTORS HERE
  //-----------------------------------------------------------
  const { activeServiceTabIndex} = useSelector(
    (state: any) => state.globalReducer
  );
  const dispatch:any = useDispatch();
  const handleTabClick = (index: any) => {
    setActiveTab(index.target.id);
    dispatch(updateTheActiveServiceTabIndex(tabsNames.findIndex((element)=>element===index.target.id)))
    handleScroll(tabsContainerRef.current)
    //setActiveTabIndex(tabsNames.findIndex((element)=>element===index.target.id))
  };

  useEffect(()=>{
    setActiveTab(tabsNames[activeServiceTabIndex]);
    setActiveTabIndex(activeServiceTabIndex);
  },[activeServiceTabIndex])

  return (
    <div ref={tabsContainerRef} className="Tabs--main_container">
      <div className="Tabs--container">
        {tabsNames.map((tabName,index) => (
          <div onClick={handleTabClick} id={tabName} {...(activeTab===tabName?{className:"Tabs--tab Tabs--tab_active"}:{className:"Tabs--tab Tabs--tab_inactive"})}>
            {tabName}
         </div>
        ))}
      </div>
      <div className="Tabs--content_container">
        {tabsContents[activeServiceTabIndex]}
      </div>
    </div>
  );
}
