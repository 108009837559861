import { useTranslation } from "react-i18next";
import "./ExperiencesSection.css";
import TeamMemberChip from "../TeamMemberChip/TeamMemberChip";
import TestimonialChip from "../TestimonialChip/TestimonialChip";
import ExperiencesCarousel from "../ExperiencesCarousel/ExperiencesCarousel";
import { useNavigate } from "react-router";
import { Link } from "react-router-dom";
import SimpleCarousel from "../SimpleCarousel/SimpleCarousel";

export default function ExperiencesSection() {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  /**------------------------------------------------------------------*/
  /**  TESTIMONIALS HERE                                               */
  /**------------------------------------------------------------------*/
  const testimonials = [
    <div>

        <TestimonialChip
              memberName="Susanita Perez Sotolongo"
              memberPosition="CEO, Good Morning Designs"
              memberAvatar="RANDOM_AVATAR 3.jpg"
              memberBio="Trabajar con esta gente fue la OSTIA!!!"
              chipHeight="230px"
            />
    </div>,
    <div>

        <TestimonialChip
          memberName="Yoana Hernandez Mesa"
          memberPosition="CEO, Rosita Fresita S.A."
          memberAvatar="RANDOM_AVATAR 2.jpg"
          memberBio="Me ayudaron con el sitio web de mi emprendimiento. Su trabajo fue excelente!"
          chipHeight="230px"
        />
    </div>,
    <div>

        <TestimonialChip
          memberName="Javier Riquimbili Moya"
          memberPosition="Colaborador"
          memberAvatar="RANDOM_AVATAR 1.jpg"
          memberBio="Excelentes en lo que hacen! El trabajo fue entregado en el tiempo pactqado y con la calidad requerida. Fue un placer ocntar con sus recomendaciones para la mejora del producto."
          chipHeight="230px"
        />
    </div>
  ];
  /**------------------------------------------------------------------*/
  /**  HANDLERS HERE                                                   */
  /**------------------------------------------------------------------*/
  const handleLocalizemLogoClick = () => {
    navigate("https://www.localizem.com/");
  };
  const handleMixologyIceLogoClick = () => {
    navigate("https://www.mixologyice.com/");
  };

  return (
    <div className="experiences_section-main_container">
      <div className="experiences_section-title">
        {t("experiencesSection-title")}
      </div>
      <div className="experiences_section-testimonials_container">
        <TestimonialChip
          memberName="Susanita Perez Sotolongo"
          memberPosition="CEO, Good Morning Designs"
          memberAvatar="RANDOM_AVATAR 3.jpg"
          memberBio="Trabajar con esta gente fue la OSTIA!!!"
          chipHeight="230px"
        />
        <TestimonialChip
          memberName="Yoana Hernandez Mesa"
          memberPosition="CEO, Rosita Fresita S.A."
          memberAvatar="RANDOM_AVATAR 2.jpg"
          memberBio="Me ayudaron con el sitio web de mi emprendimiento. Su trabajo fue excelente!"
          chipHeight="230px"
        />
        <TestimonialChip
          memberName="Javier Riquimbili Moya"
          memberPosition="Colaborador"
          memberAvatar="RANDOM_AVATAR 1.jpg"
          memberBio="Excelentes en lo que hacen! El trabajo fue entregado en el tiempo pactqado y con la calidad requerida. Fue un placer ocntar con sus recomendaciones para la mejora del producto."
          chipHeight="230px"
        />
      </div>
      <div className="experiences_section-testimonials_carousel_container">
        {/* <ExperiencesCarousel /> */}
        <SimpleCarousel autoPlay={false} interval={5000} items={testimonials}/>
        
      </div>
      
      <div className="experiences_section-colaboration_title">
        {t("experiencesSection-CollaborationsTitle")}
      </div>
      <div className="experiences_section-collaboration_description">
        {t("experiencesSection-CollaborationsDescription")}
      </div>
      <div className="experiences_section-collaborators_main_container">
        <a href="https://www.localizem.com/" target="_blank" rel="noopener noreferrer">
          <img
            height={"100px"}
            width={"150px"}
            src="localizem.svg"
            className="experiences_section-collaborators_logo"
            alt=""
          />
        </a>
        <a href="https://www.mixologyice.com/" target="_blank" rel="noopener noreferrer">
          <img
            height={"60px"}
            width={"150px"}
            src="Mixology_ICE.png"
            className="experiences_section-collaborators_logo"
            alt=""
          />
        </a>
      </div>
    </div>
  );
}
