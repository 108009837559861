import MainMenu from "../MainMenu/MainMenu";
import MobileMainMenu from "../MobileMainMenu/MobileMainMenu";
import TitleLogo from "../TitleLogo/TitleLogo";
import "./Header.css";
export default function Header() {
  return (
    <div className="header-main_container">
      <TitleLogo />
      <MainMenu />
      <MobileMainMenu />
    </div>
  );
}
