import { useTranslation } from "react-i18next";
import "./MobileMainMenu.css";
import { useState } from "react";
import { useOutsideClick } from "../../hooks/useOutsideClick";
import { useNavigate } from "react-router";
export default function MobileMainMenu() {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    //------------------------------------------------------
    //STATE VARIABLES HERE
    //------------------------------------------------------
    const [menuOpen,setMenuOpen] = useState(false);

    //------------------------------------------------------
    //HANDLERS HERE
    //------------------------------------------------------
    const handleMenuClick = ()=>{
        setMenuOpen((previous)=>!previous)
    }

    const handleMenuMouseEnter = ()=>{
        //setMenuOpen(true)
    }

    const handleMenuMouseLeave = ()=>{
        //setMenuOpen(false)
    }

    const handleClickOutsideMenu = ()=>{
      setMenuOpen(false)
    };

    const handleServicesButtonClick = ()=>{
      navigate("/services")
    }

    const handleHomeButtonClick = ()=>{
      navigate("/")
    }

    const ref:any = useOutsideClick(handleClickOutsideMenu);


  return (
    <div className="mobile_main_menu-container" onMouseLeave={handleMenuMouseLeave} >
      <div className="mobile_main_menu-menu_icon_container"  >
      <span ref={ref} className="material-icons menu_icon" onClick={handleMenuClick} onMouseEnter={handleMenuMouseEnter}>menu</span>
      </div>

      <div {...(menuOpen===true?{className:"mobile_main_menu-options_container"}:{className:"mobile_main_menu-options_container-hidden"})} >
        <button className="mobile_main_menu-menu_button" onClick={handleHomeButtonClick}>{t("inicio")}</button>
        <button className="mobile_main_menu-menu_button" onClick={handleServicesButtonClick}>{t("servicios")}</button>
        {/* <button className="mobile_main_menu-menu_button">{t("productos")}</button>
        <button className="mobile_main_menu-menu_button">{t("quienesSomos")}</button>
        <button className="mobile_main_menu-menu_button">{t("contactanos")}</button> */}
      </div>
    </div>
  );
}
