import { useTranslation } from "react-i18next";
import "./ContactMethodInput.css"
import { useDispatch, useSelector } from "react-redux";
import { updateTheMessageContactInfo,updateTheContactMethodValid } from "../../redux/actions/sendmessage";
import { useState } from "react";

export default function ContactMethodInput() {
    const { t, i18n } = useTranslation();
    const dispatch:any = useDispatch();
    //-----------------------------------------------------------
    // STATE VARIABLES HERE
    //-----------------------------------------------------------
    const [contactByMailChecked,setContactByMailChecked] = useState<boolean>(true);
    const [contactByPhoneChecked,setContactByPhoneChecked] = useState<boolean>(false);
    const [contactMethodError,setContactMethodError] = useState("");

    //-----------------------------------------------------------
    // HANDLERS HERE
    //-----------------------------------------------------------
    const onContactByMailClickHandler = ()=>{
        setContactByMailChecked(true);
        setContactByPhoneChecked(false);
        setContactMethodError("");
    };
    const onContactByPhoneClickHandler = ()=>{
        setContactByMailChecked(false);
        setContactByPhoneChecked(true);
        setContactMethodError("");
    };
    const contactMethodInputChangeHandler = (event:any)=>{
        /**AQUI TIENES QUE COMPROBAR QUE TIPO HA ELEGIDO EL CLIENTE PARA QUE LO CONTACTEN Y EN BASE A ESO DEBES
         * HACER LA VALIDACION PERTINENTE
         */
        if (contactByMailChecked===true) {
            //VALIDAR EL INPUT PARA CORREO ELECTRONICO Y NO HACER EL DISPATCH HASTA QUE CUMPLA CON LOS REQUISITOS
            if (emailValidation(event.target.value)===true) {
                dispatch(updateTheMessageContactInfo(event.target.value))
                dispatch(updateTheContactMethodValid(true));//Actualizar el store indicando que el metodo de contacto es valido
                setContactMethodError("");
            } else{
                dispatch(updateTheContactMethodValid(false));//Actualizar el store indicando que el metodo de contacto no es valido
                setContactMethodError(t("contactMehodInput_EmailError"));
            }
        }
        if (contactByPhoneChecked) {
            //VALIDAR EL INPUT PARA NUMERO TELEFONICO Y NO HACER EL DISPATCH HASTA QUE CUMPLA CON LOS REQUISITOS
            if (phoneValidation(event.target.value)===true) {
                dispatch(updateTheMessageContactInfo(event.target.value))
                dispatch(updateTheContactMethodValid(true));//Actualizar el store indicando que el metodo de contacto es valido
                setContactMethodError("");
            } else{
                setContactMethodError(t("contactMehodInput_PhoneError"));
                dispatch(updateTheContactMethodValid(false));//Actualizar el store indicando que el metodo de contacto no es valido
            }
        }
        
    };

    //-----------------------------------------------------------
    // VALIDATIONS HERE
    //-----------------------------------------------------------
    const emailValidation = (email:string) => {
        const regex = new RegExp(
          "^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$"
        );
        return regex.test(email);
      };
      const phoneValidation = (phone:string) => {
        const regex = new RegExp("^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$");
        return regex.test(phone);
      };
    return(
        <div className="contact_method-main_container">
                <div className="contact_method-contact_icon_container">
                    <div className="contact_method-label">{t("contactMethodInput_Label")}</div>
                    <div className="contact_method-icons_container">
                        <span  {...(contactByMailChecked===true?{className:"material-icons mail_icon"}:{className:"material-icons mail_icon_disabled"})} onClick={onContactByMailClickHandler}>mail</span>
                        <span {...(contactByPhoneChecked===true?{className:"material-icons phone_icon"}:{className:"material-icons phone_icon_disabled"})} onClick={onContactByPhoneClickHandler}>smartphone</span>
                    </div>
                </div>
                <input type="text" className="contact_method-input" onChange={contactMethodInputChangeHandler}/>
                <div className="contact_method-error_text">{contactMethodError}</div>
            </div>
    );
}